.subtitle-m-b-8 {
    margin-bottom: 8px !important;
}

.release-desc {
@media only screen and (max-width: 767px) {
    padding-top: 0px !important;
}
}

.display-mobile {
 display: block !important;
}