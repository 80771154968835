.not-found {
  display: flex;
  max-width: 500px;
  justify-content: center;
  align-items: center;
  flex-flow: column wrap;
}

.not-found-icon {
  width: 72px;
  height: 72px;
  color: #2f3133;
  margin: 16px 0;
}

.error {
  color: red;
}
