.arrow-size {
    width: 24px;
    height: 24px;
}

.menu-icon{
    margin-right: 4px;
}

.drawer-title { 
    position: sticky;
    top: 0px;
    margin-bottom: 34px;
}

.drawer-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px
}

.text {
    font-size: 20px !important;
    font-style: normal;
    font-family: "SFProDisplay-SemiBold" !important;
    font-weight: normal;
    line-height: 1.36;
    font-stretch: normal;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.drawer-scroll { 
    width: 97.5%;
    height: calc(100vh - 105px);
    overflow-y: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.drawer-scroll::-webkit-scrollbar {
    display: none;
}


@media (min-width: 768px) {
    .drawer-scroll { 
    height: calc(100vh - 114px);
    }
}

@media (min-width: 1360px) {
    .drawer-title { 
        position: sticky;
        top: 0px;
        margin-bottom: 40px;
    }

    .drawer-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 24px
    }

    .text {
        font-size: 20px;
        font-style: normal;
        font-family: "SFProDisplay-SemiBold";
        font-weight: normal;
        line-height: 1.36;
        font-stretch: normal;
    }

    .drawer-scroll { 
        width: 395px;
        height: calc(100vh - 130px);
        overflow-y: scroll;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }

    .drawer-scroll::-webkit-scrollbar {
        display: none;
    }
}